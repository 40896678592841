<template>
  <div>
    <el-dialog title="账号记录" :visible.sync="dialogVisible" width="590px" class="custom_dialog">
      <div class="content">
        <div v-for="(item,index) in infoList" :key="index" class="info_item">
          <span>{{ item.label }}</span>
          <span>{{ pageData[item.key] }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <p class="custom_my_btn" @click="dialogVisible = false">关 闭</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AccountRecords',
  data() {
    return {
      dialogVisible: false,
      pageData:{},
      infoList: [
        {
          label: '账号:',
          key:'username',
        }, {
          label: '账号备注:',
          key:'remark',
        }, {
          label: '创建时间:',
          key:'createdAt',
        }, {
          label: '最后登录时间:',
          key:'lastLoginAt',
        }
      ]
    }
  },
  methods: {
    showDialog(state,dialogData) {
      this.pageData = dialogData
      this.dialogVisible = state
    }
  },
}
</script>

<style lang="scss" scoped>
.info_item {
  height: Translate(36px);
  display: flex;
  span {
    &:nth-child(1) {
      flex: 1;
      text-align: right;
      color: #999999;
    }
    &:nth-child(2) {
      flex: 1;
      text-align: left;
      color: #333333;
      margin-left: Translate(10px);
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
<template>
  <div class="account_management_page">
    <div class="page_title">
      <span>
        子账号管理
        <span style="color: #999;">({{pagination.total || 0}})</span>
      </span>
      <div style="width:250px">
        <el-input placeholder="请输入账号/备注" prefix-icon="el-icon-search" size="small" clearable  v-model="searchInfo" @keyup.enter.native="toSearch" @input="toSearch"/>
      </div>
    </div>
    <div class="my_table">
      <el-table :data="tableData" style="width: 100%" border class="custom_my_table" v-loading="loading">
        <el-table-column align="center" type="index" label="序号" width="110" />
        <el-table-column align="center" v-for="(item,index) in tableColumn" :key="`column${index}`" :prop="item.prop" :label="item.label" :minWidth="item.width">
          <template slot-scope="scope">
            <div v-if="item.prop == 'username'">
              {{ scope.row[item.prop] }}
            </div>
            <div v-if="item.prop == 'password'">
              <template v-if="!scope.row.isEdit">
                <div>
                  <span>
                    {{ scope.row.isShow ? scope.row[item.prop] : '******' }}
                  </span>
                  <i :class="`iconfont ${scope.row.isShow?'icon-bukeshi': 'icon-keshi'}`" @click="showPassword(scope.row,scope.$index)"></i>
                </div>
              </template>
              <template v-else>
                <el-input size="small" clearable v-model="scope.row[item.prop]" placeholder="请输入密码"></el-input>
              </template>
            </div>
            <div v-if="item.prop == 'remark'">
              <template v-if="!scope.row.isEdit">
                {{ scope.row[item.prop] }}
              </template>
              <template v-else>
                <el-input size="small" clearable v-model="scope.row[item.prop]" placeholder="请输备注"></el-input>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="214">
          <template slot-scope="scope">
            <div class="operation">
              <template v-if="!scope.row.isEdit">
                <span @click="watch(scope.row)">查看</span>
                <span @click="changeStatus(scope.row,scope.$index,true,'edit')">编辑</span>
              </template>
              <template v-else>
                <span @click="toSave(scope.row,scope.$index)">保存</span>
                <span @click="changeStatus(scope.row,scope.$index,false,'cancel')">取消</span>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="my_pagination">
        <el-pagination background @size-change="pagination.handleSizeChange" @current-change="pagination.handleCurrentChange" :current-page="pagination.currentPage" :page-sizes="[10, 20, 30, 40]"
          :page-size="pagination.pageSize" layout="total, sizes, jumper, prev, pager, next" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <accountRecords ref="accountRecordsRef" />
  </div>
</template>

<script>
import * as api from '@/api'
import accountRecords from "./accountRecords.vue";
import {debounce} from 'lodash'
const tableColumn = [
  {
    prop: 'username',
    label: '子账号',
    width: '184'
  }, {
    prop: 'password',
    label: '登录密码',
    width: '214'
  }, {
    prop: 'remark',
    label: '备注',
    width: '216'
  }
]
export default {
  components: {
    accountRecords
  },
  data() {
    return {
      loading: false,
      tableData: [],
      tableColumn,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        handleSizeChange: '',
        handleCurrentChange: '',
      },
      searchInfo: "",
      temporaryRow: {},
    }
  },
  methods: {
    toSearch:debounce(function(){
      this.pagination.currentPage = 1
      this.pagination.pageSize = 10
      this.getChildrenList()
    },300),
    showPassword(row,index){
      this.$set(this.tableData,index,{...row,isShow:!row.isShow})
    },
    changeStatus(row, index, status, type) {
      this.$set(this.tableData, index, { ...row, isEdit: status })
      if (type == 'edit') {
        this.temporaryRow = row
      } else {
        this.$set(this.tableData, index, { ...this.temporaryRow, isEdit: status })
      }
    },
    toSave(row, index) {
      // this.$set(this.tableData, index, { ...row, isEdit: false })
      this.updateChildrenAccount(row, index)
    },
    getChildrenList() {
      this.loading = true
      let params = {
        pageNo: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        searchInfo: this.searchInfo,
      }
      api.getChildrenList(params).then(({ data: { data } }) => {
        this.loading = false
        this.pagination.total = parseInt(data.total || 0)
        this.tableData = data.records
      }).catch(err => {
        console.log(err)
      })
    },
    watch(row) {
      this.$refs.accountRecordsRef.showDialog(true, row)
    },
    updateChildrenAccount(row, index) {
      let params = {
        childrenId: row.id,
        ...row
      }
      api.updateChildrenAccount(params).then(({ data: { message }, success }) => {
        if ({ success }) {
          this.$message.success('保存成功');
          this.$set(this.tableData, index, { ...row, isEdit: false })
        } else {
          this.$message.error(message);
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mounted() {
    this.pagination.handleSizeChange = (e) => {
      this.pagination.pageSize = e
      this.getChildrenList()
    }
    this.pagination.handleCurrentChange = (e) => {
      this.pagination.currentPage = e
      this.getChildrenList()
    }
    this.getChildrenList()
  },
}
</script>

<style lang="scss" scoped>
.account_management_page {
  padding: 0px Translate(20px);
  .page_title {
    height: Translate(76px);
    line-height: Translate(76px);
    font-size: Translate(18px);
    color: #333333;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .my_table {
    .my_pagination {
      float: right;
      margin: Translate(20px) 0 Translate(40px) 0;
    }
    .operation {
      color: #4088eb;
      span {
        cursor: default;
        &:nth-child(2) {
          margin-left: Translate(10px);
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <el-dialog :title="pageParams.ifExistsPassword ?'修改密码' :'设置密码'" :visible.sync="dialogVisible" width="590px" class="custom_dialog">
      <div class="content">
        <el-form ref="form" :rules="rules" :model="formData" label-width="100px" class="custom_my_form">
          <template v-if="!pageParams.ifExistsPassword">
            <el-form-item label="登陆密码" prop="newPassword">
              <el-input v-model="formData.newPassword" placeholder="请输入登陆密码" size="small" style="width: 280px;" show-password maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
              <el-input v-model="formData.confirmPassword" placeholder="请输入确认密码" size="small" style="width: 280px;" show-password maxlength="20"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input v-model="formData.oldPassword" placeholder="请输入登陆密码" size="small" style="width: 280px;" show-password maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model="formData.newPassword" placeholder="请输入确认密码" size="small" style="width: 280px;" show-password maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPassword">
              <el-input v-model="formData.confirmPassword" placeholder="请输入确认密码" size="small" style="width: 280px;" show-password maxlength="20"></el-input>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <p class="custom_my_btn" @click="save">确 定</p>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import * as api from '@/api'
const modifyRules = {
  newPassword: [
    { required: true, message: '请输入登陆密码', trigger: 'blur' },
  ],
  confirmPassword: [
    { required: true, message: '请输入确认密码', trigger: 'blur' },
  ],
}
const resetRules = {
  oldPassword: [
    { required: true, message: '请输入旧密码', trigger: 'blur' },
  ],
  newPassword: [
    { required: true, message: '请输入新密码', trigger: 'blur' },
  ],
  confirmPassword: [
    { required: true, message: '请输入确认新密码', trigger: 'blur' },
  ],
}
export default {
  name: 'EditPasswordDialog',
  data() {
    return {
      dialogVisible: false,
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      rules: {},
      pageParams: {
        ifExistsPassword: false, //是否存在密码
      }
    }
  },
  methods: {
    showDialog(state, params) {
      this.dialogVisible = state
      this.pageParams = params
      this.rules = this.pageParams.ifExistsPassword ? resetRules : modifyRules
    },
    save() {
      const { newPassword, confirmPassword } = this.formData
      if (newPassword != confirmPassword) {
        let targetRef = this.pageParams.ifExistsPassword ? this.$refs.form.fields[2] : this.$refs.form.fields[1]
        targetRef.validateMessage = '两次密码不一致'
        targetRef.validateState = 'error'
        return false
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            ifReset: this.pageParams.ifExistsPassword ? true : false,//是否重置
            ifLogout: true,//是否注销登录
            ...this.formData
          }
          api.updatePassword(params).then(({ success }) => {
            if (success) {
              this.$message.success(this.pageParams.ifExistsPassword ? '密码修改成功' : '密码设置成功')
              this.$store.dispatch('logOut')
              this.$router.push('/home')
            }
          }).catch(err => {
            console.log(err)
          })
          this.dialogVisible = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
  </script>
  
  <style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep .custom_my_form {
  .el-form-item {
    .el-form-item__label {
      color: #333333;
      padding: 0 15px 0 0;
    }
  }
}
</style>
<template>
  <div class="accountIn_formation_page">
    <div class="page_title">账号信息</div>
    <div class="my_form" v-loading="loading">
      <el-form ref="form" label-width="130px" class="custom_form">
        <el-form-item label="头像">
          <div class="edit_avatar">
            <img src="https://images.zzt.com.cn/ks/2023/05/11/avatar.png">
            <!-- <img :src="userInfo.avatar"> -->
            <!-- <span class="edit">修改</span> -->
          </div>
        </el-form-item>
        <el-form-item label="昵称">
          <div class="item_content">
            <span>{{userInfo.nickName}}</span>
            <span class="edit" @click="editName">修改</span>
          </div>
        </el-form-item>
        <el-form-item label="所属组织">
          <div class="item_content">
            <span>{{userInfo.organizationName}}</span>
          </div>
        </el-form-item>
        <el-form-item label="账户等级">
          <div class="item_content">
            <span>{{userInfo.accountTypeCode == 1 ? '主账号' : '子账号'}}</span>
          </div>
        </el-form-item>
        <el-form-item label="账户权限">
          <div class="item_content authority_list">
            <span class="authority_btn btn_active" v-for="(item,index) in userInfo.productRightsList" :key="index">{{ item.rightsTypeName }}{{ item.rightsLevelName?`(${item.rightsLevelName})`:'' }}</span>
            <span class="edit" style="marginLeft: 14px;display: inline-block;" @click="openPermission()">权限开通</span>
          </div>
        </el-form-item>
        <template v-if="userInfo.accountTypeCode == 1">
          <el-form-item label="手机号码">
            <div class="item_content">
              <span>{{userInfo.phone}}</span>
              <!-- <span class="edit" @click="editPhone">重新绑定</span> -->
            </div>
          </el-form-item>
          <el-form-item label="邮箱">
            <div class="item_content">
              <span>{{userInfo.email}}</span>
              <!-- <span class="edit" @click="editEmail">重新绑定</span> -->
            </div>
          </el-form-item>
          <el-form-item label="密码">
            <div class="item_content">
              <span class="edit" style="marginLeft:0px" @click="editPassword">{{userInfo.ifExistsPassword?'重置':'设置密码'}}</span>
            </div>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <!-- 联系客服 -->
    <customerServiceDialog ref="customerServiceRef" />
    <editNameDialog ref="editNameRef" @updatePage="getPersonalInfo" />
    <editPasswordDialog ref="editPasswordRef" />
    <editPhoneDialog ref="editPhoneRef" />
    <editEmailDialog ref="editEmailRef" />
  </div>
</template>

<script>
import * as api from '@/api'
import customerServiceDialog from "@/components/customerServiceDialog.vue";
import editNameDialog from "./editNameDialog.vue";
import editPasswordDialog from "./editPasswordDialog.vue";
import editPhoneDialog from "./editPhoneDialog.vue";
import editEmailDialog from "./editEmailDialog.vue";
export default {
  components: {
    customerServiceDialog,
    editNameDialog,
    editPasswordDialog,
    editPhoneDialog,
    editEmailDialog,
  },
  data() {
    return {
      loading: false,
      userInfo: {
        avatar: 'https://images.zzt.com.cn/ks/2023/05/11/avatar.png',
        nickName: '',
        organizationName: '',
        accountType: '',
        authority: '',
        phone: '',
        email: '',
        password: '',
        accountTypeCode:'',
        productRightsList:[],
      },
    }
  },
  methods: {
    openPermission() {
      this.$refs.customerServiceRef.showDialog(true)
    },
    editName() {
      this.$refs.editNameRef.showDialog(true)
    },
    editPassword() {
      this.$refs.editPasswordRef.showDialog(true, { ifExistsPassword: this.userInfo.ifExistsPassword })
    },
    editPhone() {
      this.$refs.editPhoneRef.showDialog(true, { status: 0 })
    },
    editEmail() {
      this.$refs.editEmailRef.showDialog(true, { status: 1 })
    },
    getPersonalInfo() {
      this.loading = true
      api.getPersonalInfo().then(({ data: { data } }) => {
        this.userInfo = data
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    initEquity() {
      let equityInformation = this.$store.state.equityInformation
      this.authorityList[1].hasAuthority = equityInformation.pateAccountProductRightsDTO?.ifProductRight
      this.authorityList[2].hasAuthority = equityInformation.techAccountReportRightDTO?.ifReportRight
    }
  },
  mounted() {
    this.getPersonalInfo()
    // this.initEquity()
  },
}

</script>

<style lang="scss" scoped>
.accountIn_formation_page {
  .page_title {
    height: Translate(48px);
    line-height: Translate(48px);
    font-size: Translate(18px);
    color: #333333;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #f1f1f1;
    padding-left: Translate(20px);
  }
  .my_form {
    margin-top: Translate(34px);
    .edit {
      font-size: Translate(14px);
      color: #4088eb;
      margin-left: Translate(24px);
      cursor: pointer;
    }
    .item_content {
      text-align: left;
    }
    .edit_avatar {
      display: flex;
      img {
        width: Translate(40px);
        height: Translate(40px);
        border-radius: Translate(20px);
      }
    }
    .authority_list {
      .authority_btn {
        display: inline-block;
        // min-width: Translate(88px);
        padding: 0 Translate(20px);
        height: Translate(32px);
        background: #f5f6f9;
        border-radius: Translate(4px);
        font-size: Translate(14px);
        color: #bbbbbb;
        margin-right: Translate(10px);
        text-align: center;
        line-height: Translate(32px);
        cursor: default;
      }
      .btn_active {
        position: relative;
        color: #333333;
        &::after {
          content: '';
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: Translate(22px);
          height: Translate(22px);
          background-image: url('https://images.zzt.com.cn/ns/2023/06/07/select_active.png');
          background-size: Translate(22px) Translate(22px);
        }
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 16px;
  .el-form-item__label {
    font-size: 14px;
    color: #999999;
    padding: 0 40px 0 0;
  }
}
</style>
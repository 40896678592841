<template>
  <div>
    <el-dialog :title="status == 0 ?'绑定邮箱' :'修改邮箱'" :visible.sync="dialogVisible" width="590px" class="custom_dialog">
      <div class="content">
        <el-form ref="form" :rules="rules" :model="formData" label-width="100px" class="custom_my_form">
          <template v-if="status == 0">
            <el-form-item label="邮箱地址" prop="emailUrl">
              <el-input v-model="formData.emailUrl" placeholder="请输入邮箱地址" size="small" style="width: 280px;"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="verificationCode">
              <code-input v-model="formData.verificationCode" :targetNum="formData.emailUrl" type="email"/>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="原邮箱地址" prop="oldemailUrl">
              <el-input v-model="formData.oldemailUrl" placeholder="请输入原邮箱地址" size="small" style="width: 280px;"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="oldCode" class="code">
              <code-input v-model="formData.oldCode" :targetNum="formData.oldemailUrl"  type="email"/>
            </el-form-item>
            <el-form-item label="新邮箱地址" prop="newemailUrl">
              <el-input v-model="formData.newemailUrl" placeholder="请输入新邮箱地址" size="small" style="width: 280px;"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="newCode">
              <code-input v-model="formData.newCode" :targetNum="formData.newemailUrl" type="email"/>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <p class="custom_my_btn" @click="save">确 定</p>
      </div>
    </el-dialog>
  </div>
</template>
      
  <script>
import codeInput from "@/components/codeInput.vue";
const bindingRules = {
  emailUrl: [
    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
  ],
  verificationCode: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
}
const editRules = {
  oldemailUrl: [
    { required: true, message: '请输入原邮箱地址', trigger: 'blur' },
  ],
  oldCode: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
  newemailUrl: [
    { required: true, message: '请输入新邮箱地址', trigger: 'blur' },
  ],
  newCode: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
}
export default {
  name: 'EditPhoneDialogDialog',
  components: {
    codeInput,
  },
  data() {
    return {
      dialogVisible: false,
      status: 0,//0修改，1重置
      formData: {
        emailUrl: '',
        verificationCode: '',
        oldemailUrl: '',
        oldCode: '',
        newemailUrl: '',
        newCode: '',
      },
      rules: {},
    }
  },
  methods: {
    showDialog(state, params) {
      this.dialogVisible = state
      this.status = params.status
      this.rules = params.status == 0 ? bindingRules : editRules
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogVisible = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  computed: {
  },
}
      </script>
      
      <style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep .custom_my_form {
  .el-form-item {
    .el-form-item__label {
      color: #333333;
      padding: 0 15px 0 0;
    }
  }
}
.code {
  position: relative;
  .captcha {
    width: Translate(80px);
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .error {
    position: absolute;
    color: #f56c6c;
    bottom: -35px;
    font-size: Translate(12px);
    line-height: Translate(20px) px;
    font-weight: 400;
  }
  .get-code {
    position: absolute;
    font-size: Translate(14px);
    color: #4088eb;
    font-weight: 400;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: #fff;
    padding: 0;
  }
}
</style>
<template>
  <div class="allocation_list_page">
    <div class="choice_box" v-if="accountType == 1">
      <div class="line-two">
        <div class="choice_item">
          <span class="item_label">省份地区：</span>
          <div>
            <!-- <span :class="`${regionVal ? 'select_span' : 'select_span select_span_active'}`" @click="noRegion">不限</span> -->
            <el-cascader placeholder="请选择所在省市区" v-model="regionVal" :options="regionOptions" @change="handleChange"
              :props="props" size="small" clearable style="width: 180px;"></el-cascader>
          </div>
        </div>
        <div class="choice_item ml-30">
          <span class="item_label">分配状态：</span>
          <div>
            <!-- <span :class="`${statusActive == item.value ? 'select_span select_span_active' : 'select_span'}`" v-for="(item,index) in statusOpts" :key="index" @click="handleSelect(item)">{{ item.label }}</span> -->
            <el-select v-model="statusActive" placeholder="请选择" size="small" clearable @change="handleSelect"
              style="width: 180px;">
              <el-option v-for="item in statusOpts" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="choice_item ml-30">
          <span class="item_label">账号归属：</span>
          <div>
            <el-select v-model="usersId" placeholder="请选择子账号名称" size="small" clearable @change="handleSelect"
              style="width: 180px;">
              <el-option v-for="item in subAccountOpts" :key="item.id" :label="item.username" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- <div class="choice_item">
        <span class="item_label">账号归属：</span>
        <div>
          <el-select v-model="usersId" placeholder="请选择子账号名称" size="small" clearable @change="handleSelect">
            <el-option v-for="item in subAccountOpts" :key="item.id" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div> -->
    </div>
    <div class="table_box">
      <div class="page_title">
        <span>
          {{ routeQuery.name }}
          <span style="color: #999;">({{ pagination.total || 0 }})</span>
        </span>
        <div style="display: flex;align-items: center;">
          <p class="select_num_text" v-if="accountType == 1 && (selectNum != 0 || selectRow.length > 0)">已选{{
            selectRow.length > 0 ?
              selectRow.length : selectNum }}家</p>
          <el-popover placement="bottom" width="264" trigger="click" v-model="showPop"
            @hide="batchObj.subAccountSelectVal = ''" v-if="accountType == 1">
            <div class="popover_content">
              <div class="item">
                <p>企业序号</p>
                <div class="enterprise_index">
                  <div class="input_box">
                    <el-input size="small" clearable placeholder="开始序号" v-model="batchObj.startIndex"
                      :disabled="selectRow.length > 0" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" />
                  </div>
                  ~
                  <div class="input_box">
                    <el-input size="small" clearable placeholder="结束序号" v-model="batchObj.endIndex"
                      :disabled="selectRow.length > 0" @input="changeEndVal" />
                  </div>
                </div>
              </div>
              <div class="item">
                <p>分配子账号</p>
                <div class="my_select_box">
                  <el-select v-model="batchObj.subAccountSelectVal" filterable placeholder="请选择" size="small"
                    style="width: 250px;">
                    <el-option v-for="item in subAccountOpts" :key="item.id" :label="item.username" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="foot_btn">
                <span @click="closePop">取消</span>
                <span @click="toDistribution">确定</span>
              </div>
            </div>
            <div slot="reference">
              <p class="custom_my_btn">批量操作
                <i class="el-icon-arrow-down"></i>
              </p>
            </div>
          </el-popover>

          <div style="width: 200px;">
            <el-input placeholder="请输入企业名称" prefix-icon="el-icon-search" size="small" clearable v-model="searchVal"
              @keyup.enter.native="toSearch" @input="toSearch" />
          </div>
        </div>
      </div>
      <div class="my_table">
        <!-- @sort-change="changeSort" -->
        <el-table :data="tableData" style="width: 100%" border class="custom_my_table"
          @selection-change="handleSelectionChange" v-loading="loading">
          <el-table-column type="selection" width="55" v-if="this.accountType == 1" />
          <el-table-column align="center" type="index" label="序号" width="56">
            <template slot-scope="{$index}">
              {{ $index + 1 + (pagination.currentPage - 1) * pagination.pageSize }}
            </template>
          </el-table-column>
          <el-table-column align="left" :prop="item.prop" :label="item.label" :minWidth="item.width"
            v-for="(item, index) in tableColumn" :key="index">
            <!-- :sortable="item.sortable" -->
            <template slot-scope="scope">
              <span v-if="item.prop == 'nameCn'" @click="jumpPage(scope.row)" style="color:#4088eb;cursor: pointer;">
                {{ scope.row[item.prop] }}
              </span>
              <span v-else>
                {{ scope.row[item.prop] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="分配子账号" width="214" v-if="accountType == 1">
            <template slot-scope="scope">
              <el-select v-model="scope.row.userId" filterable placeholder="未分配" size="small"
                @change="dispatchEnterpriseToAccountChildren(scope.row)" clearable>
                <el-option v-for="item in subAccountOpts" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
        <div class="my_pagination">
          <el-pagination background @size-change="pagination.handleSizeChange"
            @current-change="pagination.handleCurrentChange" :current-page="pagination.currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pagination.pageSize"
            layout="total, sizes, jumper, prev, pager, next" :total="pagination.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import { debounce } from 'lodash'
const mainAccountColumn = [
  {
    prop: 'nameCn',
    label: '企业名称',
    width: '255',
    sortable: false,
  }, {
    prop: 'enterpriseProvinceCityCounty',
    label: '所在省市区',
    width: '114',
    sortable: false,
  }, {
    prop: 'enterpriseAddress',
    label: '企业地址',
    width: '196',
    sortable: false,
  }
]
const subAccountColumn = [
  {
    prop: 'nameCn',
    label: '企业名称',
    width: '190',
    sortable: false,
  }, {
    prop: 'enterpriseProvinceCityCounty',
    label: '所在省市区',
    width: '114',
    sortable: false,
  }, {
    prop: 'enterprisePhone',
    label: '联系方式',
    width: '150',
    sortable: false,
  }, {
    prop: 'enterpriseEmail',
    label: '邮箱地址',
    width: '160',
    sortable: false,
  },
  {
    prop: 'followGroupingName',
    label: '关注列表',
    width: '120',
    sortable: false,
  },
  {
    prop: 'createdAt',
    label: '创建时间',
    width: '120',
    sortable: true,
  },
  // {
  //   prop: 'contactName',
  //   label: '联系人',
  //   width: '100'
  // }, {
  //   prop: 'contactPhone',
  //   label: '联系人活动电话',
  //   width: '150'
  // }
]
export default {
  data() {
    return {
      accountType: this.$store.state.userInfo.accountType,
      routeQuery: this.$route.query,
      loading: false,
      tableColumn: [],
      selectNum: 0,
      showPop: false,
      regionOptions: [],
      subAccountOpts: [],
      regionVal: '',
      statusActive: '',
      usersId: '',
      searchVal: '',
      selectRow: [],
      batchObj: {
        subAccountSelectVal: '',
        startIndex: '',
        endIndex: '',
      },
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        handleSizeChange: '',
        handleCurrentChange: '',
      },
      tableData: [],
      statusOpts: [
        {
          label: '全部',
          value: 0,
        },
        {
          label: '未分配',
          value: 1,
        }, {
          label: '已分配',
          value: 2,
        }
      ],
      props: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true,
      }
    }
  },
  methods: {
    jumpPage(row) {
      let url = process.env.VUE_APP_MODE === 'prod' ? `https://pate.zzt.com.cn/enterprise/detail?id=${row.enterpriseInfoId}&token=${this.$store.state.token}` : `https://test-pate.zzt.com.cn/enterprise/detail?id=${row.enterpriseInfoId}&token=${this.$store.state.token}`
      window.open(url)
    },
    toSearch: debounce(function () {
      this.pagination.currentPage = 1
      this.pagination.pageSize = 10
      this.getRosterEnterpriseList()
    }, 300),
    handleChange(e) {
      this.pagination.currentPage = 1
      this.getRosterEnterpriseList()
    },
    handleSelect() {
      this.pagination.currentPage = 1
      this.getRosterEnterpriseList()
    },
    handleSelectionChange(e) {
      this.batchObj.startIndex = ''
      this.batchObj.endIndex = ''
      this.showPop = e.length > 0
      this.selectNum = e.length
      this.selectRow = e
      console.log('this.selectRow', this.selectRow)
    },
    closePop() {
      this.showPop = false
      this.batchObj = {
        subAccountSelectVal: '',
        startIndex: '',
        endIndex: '',
      }
    },
    toDistribution() {
      if (this.selectRow.length > 0) {
        this.dispatchEnterpriseToAccountChildren()
      } else {
        let falg = Number(this.batchObj.startIndex) < Number(this.batchObj.endIndex)
        if (falg) {
          if (this.batchObj.subAccountSelectVal) {
            this.dispatchEnterpriseToAccountChildren()
          } else {
            this.$message.error('请选择要分配的子账号')
          }
        } else {
          this.$message.error('结束序号需大于开始序号')
        }
      }
      this.showPop = false
    },
    getRosterEnterpriseList() {
      this.loading = true
      let params = {
        rosterId: this.routeQuery.id,
        pageNo: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        search: this.searchVal,
        dipatchStatus: this.statusActive == 1 ? false : this.statusActive == 2 ? true : '',
        usersIdList: this.usersId ? [this.usersId] : [],
        sort: 'ASC',
        sortFiled: 'id'
      }
      if (this.regionVal.length > 0) {
        params.provinceCode = this.regionVal[0] || ''
        params.cityCode = this.regionVal[1] || ''
        params.countyCode = this.regionVal[2] || ''

      }
      api.getRosterEnterpriseListV2(params).then(({ data: { data }, success }) => {
        this.loading = false
        if (success) {
          this.tableData = data.records
          this.pagination.total = parseInt(data.total || 0)
        }
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    //获取子账号列表
    getRosterAccountChildrenList() {
      let params = {
        userId: this.$store.state.userInfo.id
      }
      api.getRosterAccountChildrenList(params).then(({ data: { data }, success }) => {
        if (success) {
          console.log('api.getRosterAccountChildrenList', data)
          this.subAccountOpts = data
        }

      }).catch(err => {
        console.log(err)
      })
    },
    // 分发子账号
    dispatchEnterpriseToAccountChildren(row = null) {
      let ids = []
      if (this.selectRow.length > 0) {
        ids = this.selectRow.map(item => {
          return item.id
        })
      }
      if (row) {
        ids = [row.id]
      }
      let params = {
        userId: row?.userId || this.batchObj.subAccountSelectVal,
        ids,
        sort: 'ASC',
        sortFiled: 'id',
        startNo: parseInt(this.batchObj.startIndex),
        endNo: parseInt(this.batchObj.endIndex),
        rosterId: this.routeQuery.id,
        search: this.searchVal,
        dipatchStatus: this.statusActive == 1 ? false : this.statusActive == 2 ? true : '',
        usersIdList: this.usersId ? [this.usersId] : [],
      }
      if (this.regionVal.length > 0) {
        params.provinceCode = this.regionVal[0] || ''
        params.cityCode = this.regionVal[1] || ''
        params.countyCode = this.regionVal[2] || ''

      }
      api.dispatchEnterpriseToAccountChildrenV2(params).then(res => {
        console.log('api.dispatchEnterpriseToAccountChildren', res)
        this.getRosterEnterpriseList()
        this.$message.success('分配成功')
      }).catch(err => {
        console.log(err)
      })
    },
    //获取区域穿梭框数据
    getAllCityTreeNodeList() {
      api.getAllCityTreeNodeList().then(({ data: { data }, success }) => {
        // console.log('api.getAllCityTreeNodeList', res)
        if (success) {
          this.regionOptions = data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //取消地区选择
    noRegion() {
      this.regionVal = ''
      this.getRosterEnterpriseList()
    },
    changeSort(column, prop, order) {
      console.log('column, prop, order', column, prop, order)
      this.tableData = []
    },
    changeEndVal(value) {
      let newVal = value.replace(/^(0+)|[^\d]+/g, '')
      if (newVal > this.pagination.total) {
        newVal = this.pagination.total
        this.$message.error(`结束序号不能大于${this.pagination.total}`)
      }
      this.batchObj.endIndex = newVal
    }
  },
  mounted() {
    //账号类型
    if (this.accountType == 1) {
      //主账号
      this.getRosterAccountChildrenList()
      this.getAllCityTreeNodeList()
      this.tableColumn = mainAccountColumn
    } else {
      //子账号
      // this.tableColumn = this.routeQuery.activityId ? subAccountColumn :
      //   subAccountColumn.filter(item => item.prop !== 'contactName' && item.prop !== 'contactPhone')
      this.tableColumn = subAccountColumn
    }
    this.pagination.handleSizeChange = (e) => {
      this.pagination.pageSize = e
      this.getRosterEnterpriseList()
    }
    this.pagination.handleCurrentChange = (e) => {
      this.pagination.currentPage = e
      this.getRosterEnterpriseList()
    }
    this.getRosterEnterpriseList()
  },
  computed: {
    interval() {
      return (this.batchObj.endIndex - this.batchObj.startIndex) > 0 ? (this.batchObj.endIndex - this.batchObj.startIndex + 1) : 0
    }
  },
  watch: {
    interval(newVal) {
      this.selectNum = newVal
    }
  }

}
</script>

<style lang="scss" scoped>
.allocation_list_page {
  background-color: #f5f6f9;

  .choice_box {
    font-size: Translate(14px);
    background-color: #fff;
    padding: Translate(22px) Translate(20px);
    border-radius: Translate(5px);
    margin-bottom: Translate(16px);

    .line-two {
      display: flex;
      align-items: center;

      .ml-30 {
        margin-left: Translate(30px)
      }
    }

    .choice_item {
      display: flex;
      height: Translate(48px);
      display: flex;
      align-items: center;
    }

    .item_label {
      color: #999999;
      margin-right: Translate(8px);
    }

    .select_span {
      display: inline-block;
      color: #333;
      cursor: default;
      margin-right: Translate(40px);
      padding: Translate(4px) Translate(10px);
    }

    .select_span_active {
      color: #4088eb;
      background: #e9f4ff;
      border-radius: Translate(4px);
    }
  }

  .table_box {
    background-color: #fff;
    padding: 0px Translate(20px);

    .page_title {
      height: Translate(76px);
      line-height: Translate(76px);
      font-size: Translate(18px);
      color: #333333;
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      .select_num_text {
        color: #666;
        font-size: Translate(14px);
        font-weight: 400;
        margin-right: Translate(15px);
      }
    }

    .my_table {
      .my_pagination {
        float: right;
        margin: Translate(20px) 0 Translate(40px) 0;
      }

      .operation {
        color: #4088eb;

        span {
          cursor: default;
        }
      }
    }
  }
}

.popover_content {
  padding: Translate(8px);

  .item {
    color: #333;

    // margin: Translate(10px) 0 Translate(20px) 0;
    &:nth-child(2) {
      margin-top: Translate(30px);
    }

    .enterprise_index {
      margin-top: Translate(10px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input_box {
        width: Translate(105px);
      }
    }

    .my_select_box {
      margin-top: Translate(10px);
    }
  }

  .foot_btn {
    text-align: right;
    margin-top: Translate(20px);

    span {
      cursor: default;

      &:nth-child(2) {
        color: #4088eb;
        margin-left: Translate(20px);
      }
    }
  }
}
</style>
<template>
  <div>
    <el-dialog title="修改昵称" :visible.sync="dialogVisible" width="590px" class="custom_dialog">
      <div class="content">
        <el-form ref="form" :rules="rules" :model="formData" label-width="100px" class="custom_my_form">
          <el-form-item label="用户昵称" prop="nickName">
            <el-input v-model="formData.nickName" placeholder="请输入用户昵称" size="small" maxlength=20 style="width: 280px;"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <p class="custom_my_btn" @click="save">保 存</p>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import * as api from '@/api'
export default {
  name: 'editNameDialog',
  data() {
    return {
      dialogVisible: false,
      formData: {},
      rules: {
        nickName: [
          { required: true, message: '请输入用户昵称', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    showDialog(state) {
      this.dialogVisible = state
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            nickName: this.formData.nickName,
          }
          api.updateNickName(params).then(({ success }) => {
            if (success) {
              this.dialogVisible = false
              this.$message.success('修改成功')
              this.$store.commit('setUserInfo', { ...this.$store.state.userInfo, nickname: this.formData.nickName })
              this.$emit('updatePage', true)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
  </script>
  
  <style lang="scss" scoped>
.content {
  height: Translate(130px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep .custom_my_form {
  .el-form-item {
    .el-form-item__label {
      color: #333333;
      padding: 0 15px 0 0;
    }
  }
}
</style>
<template>
  <div class="my_list_page" v-loading="downloadLoading" element-loading-text="文件正在生成中...">
    <div class="search-main">
      <div class="search-item">
        <span class="title">来源：</span>
        <div>
          <el-select v-model="searchObj.type" placeholder="请选择" size="small" clearable @change="toSearch"
            style="width: 180px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item select-time">
        <span class="title">创建时间：</span>
        <div>
          <el-date-picker v-model="searchObj.time" type="datetimerange" range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间" size="small" value-format="yyyy-MM-dd HH:mm:ss" @change="toSearch">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="page_title">
        <span>
          我的名单
          <span style="color: #999;">({{ pagination.total || 0 }})</span>
        </span>
        <!-- <div style="width:250px">
          <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" clearable v-model="searchVal"
            @keyup.enter.native="toSearch" @input="toSearch" />
        </div> -->
      </div>
      <div class="my_table">
        <el-table :data="tableData" style="width: 100%" border class="custom_my_table" v-loading="loading">
          <el-table-column align="left" prop="name" label="名称" width="220">
            <template slot-scope="scope">
              <template v-if="scope.row.isEdit">
                <el-input v-model="scope.row.name" placeholder="请输入名称" clearable maxLength="30"></el-input>
              </template>
              <template v-else>
                {{ scope.row.name }}
              </template>
            </template>
          </el-table-column>
          <el-table-column align="left" prop="type" label="来源" width="84">
          </el-table-column>
          <el-table-column align="left" prop="creator" label="创建人" width="84"></el-table-column>
          <el-table-column align="left" prop="createdAt" label="创建时间" width="140">
          </el-table-column>
          <el-table-column align="left" prop="updatedAt" label="最后更新时间" width="140"></el-table-column>
          <el-table-column align="left" prop="taskStatus" label="状态" width="100">
            <template slot-scope="scope">
              <template v-if="scope.row.taskStatus === '生成中'">
                <i class="el-icon-loading"></i>
                {{ scope.row.taskStatus }}
              </template>
              <template v-if="scope.row.taskStatus === '成功'">
                <i class="dots-icon"></i>
                {{ scope.row.taskStatus }}
              </template>
              <template v-if="scope.row.taskStatus === '失败'">
                <i class="dots-icon error-dots"></i>
                {{ scope.row.taskStatus }}
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="160">
            <template slot-scope="scope">
              <div class="operation">
                <template v-if="!scope.row.isEdit">
                  <span v-if="scope.row.taskStatus === '生成中'" @click="refreshStatus(scope.row)"
                    style="margin-right: 10px;">
                    刷新
                  </span>
                  <span @click="editName(scope.$index)" v-if="scope.row.type !== '管理员分配' && scope.row.type !== '邮件推送'"
                    class="mr-10">修改名称</span>
                  <span @click="watch(scope.row)" class="mr-10" v-if="scope.row.taskStatus === '成功'">
                    {{ userInfo.accountType == 1 ? '分配' : '查看' }}
                  </span>
                  <span v-if="scope.row.taskStatus === '成功'" @click="dowloadFile(scope.row)">下载</span>
                </template>
                <template v-else>
                  <span @click="save(scope.row, scope.$index)" class="mr-10">保存</span>
                  <span @click="cancel(scope.$index)">取消</span>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="my_pagination">
          <el-pagination background @size-change="pagination.handleSizeChange"
            @current-change="pagination.handleCurrentChange" :current-page="pagination.currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pagination.pageSize"
            layout="total, sizes, jumper, prev, pager, next" :total="pagination.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as api from '@/api'
import { debounce } from 'lodash'
let updateTimer = null
export default {
  data() {
    return {
      accountType: '',
      loading: false,
      searchVal: "",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        handleSizeChange: '',
        handleCurrentChange: '',
      },
      tableData: [],
      options: [
        {
          label: '后台导入',
          value: 'default',
        },
        {
          label: '活动名单',
          value: 'activity',
        },
        {
          label: '筛选导出',
          value: 'advancedSearchExport',
        },
        {
          label: '邮件推送',
          value: 'product',
        },
        {
          label: '管理员分配',
          value: 'adminPushImport',
        }
      ],
      searchObj: {
        time: [],
        type: '',
      },
      downloadLoading: false,
    }
  },
  methods: {
    watch(row) {
      let query = {
        id: row.id,
        name: row.name,
        activityId: row?.activityId
      }
      this.$router.push({ name: 'AllocationList', query })
    },
    editName(index) {
      let isEdit = !this.tableData[index].isEdit
      this.$set(this.tableData[index], 'oldName', this.tableData[index].name)
      this.$set(this.tableData[index], 'isEdit', isEdit)
    },
    save(row, index) {
      let params = {
        rosterId: row.id,
        rosterName: row.name,
      }
      api.updateRosterName(params).then(({ success, data: { message } }) => {
        if (success) {
          this.$message.success('名单名称已修改')
          this.tableData[index].isEdit = false
        } else {
          this.$message.error(`${message ? message : '名单名称修改失败'}`)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    cancel(index) {
      this.tableData[index].isEdit = false
      this.tableData[index].name = this.tableData[index].oldName
    },
    toSearch: debounce(function () {
      this.pagination.currentPage = 1
      this.pagination.pageSize = 10
      this.getRosterList()
    }, 150),
    getRosterList() {
      this.loading = true
      let params = {
        pageNo: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        // search: this.searchVal,
        type: this.searchObj.type,
      }
      if (this.searchObj.time && this.searchObj.time.length > 0) {
        params.createdTimeStart = this.searchObj.time[0],
          params.createdTimeEnd = this.searchObj.time[1]
      }
      api.getRosterPageV2(params).then(({ data: { data }, success }) => {
        if (success) {
          this.tableData = data.records
          this.pagination.total = parseInt(data.total || 0)
          this.loading = false
          this.updateListStatus()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    updateListStatus() {
      let that = this
      function updateData() {
        let params = {
          pageNo: that.pagination.currentPage,
          pageSize: that.pagination.pageSize,
          type: that.searchObj.type,
        }
        if (that.searchObj.time && that.searchObj.time.length > 0) {
          params.createdTimeStart = that.searchObj.time[0],
            params.createdTimeEnd = that.searchObj.time[1]
        }
        api.getRosterPageV2(params).then(({ data: { data }, success }) => {
          if (success) {
            data.records.forEach(item => {
              that.tableData.forEach((its, idx) => {
                if (item.id === its.id) {
                  that.tableData[idx].taskStatus = item.taskStatus
                }
              })
            })
            let newCount = that.tableData.filter(item => item.taskStatus === '生成中').length
            if (newCount === 0) {
              clearInterval(updateTimer)
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
      let count = this.tableData.filter(item => item.taskStatus === '生成中').length
      if (count > 0) {
        //有在生成中
        updateTimer = setInterval(() => {
          updateData()
        }, 10000)
      } else {
        //单页全部生成完成
        clearInterval(updateTimer)
      }
    },
    dowloadFile(row) {
      this.buildExportTask(row.id, row.name)
    },
    buildExportTask(id, name) {
      this.downloadLoading = true
      let params = {
        rosterId: id
      }
      api.buildExportTask(params).then(({ data: { data }, success }) => {
        if (success) {
          console.log(data)
          this.getExportTaskStatus(data, name)
        } else {
          this.downloadLoading = false
          this.$message.success('文件生成失败')
        }
      }).catch(err => {
        console.log(err)
        this.downloadLoading = false
        this.$message.success('文件生成失败')
      })
    },
    getExportTaskStatus(taskId, name) {
      let that = this
      function getStatus() {
        let timer = 0
        api.getExportTaskStatus({ taskId }).then(({ data: { data }, success }) => {
          if (success) {
            console.log('状态', data)
            if (data.taskStatus === 'wait' || data.taskStatus === 'queue') {
              timer = setTimeout(() => {
                getStatus()
              }, 2000);
            } else if (data.taskStatus === 'success') {
              clearTimeout(timer)
              that.getDownloadUrl(taskId, name)
            } else {
              console.log('导出中断')
              clearTimeout(timer)
              this.downloadLoading = false
              this.$message.success('文件生成失败')
            }
          } else {
            this.downloadLoading = false
            this.$message.success('文件生成失败')
          }
        }).catch(err => {
          this.downloadLoading = false
          this.$message.success('文件生成失败')
        })
      }
      getStatus()
    },
    getDownloadUrl(taskId, name) {
      api.getDownloadUrl({ taskId, specifiedFileName: name }).then(({ data: { data }, success }) => {
        if (success) {
          console.log('getDownloadUrl', data)
          this.downloadFile(data)
        } else {
          this.downloadLoading = false
          this.$message.success('文件生成失败')
        }
      }).catch(err => {
        console.log(err)
        this.downloadLoading = false
        this.$message.success('文件生成失败')
      })
    },
    downloadFile(url, fileName = '') {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName ? fileName : url.split('/').pop(); // 或者使用自定义文件名  
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.downloadLoading = false
      this.$message.success('文件下载完成')
    },
    //立即刷新
    refreshStatus(row) {
      let params = {
        pageNo: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        type: this.searchObj.type,
      }
      if (this.searchObj.time && this.searchObj.time.length > 0) {
        params.createdTimeStart = this.searchObj.time[0],
          params.createdTimeEnd = this.searchObj.time[1]
      }
      api.getRosterPageV2(params).then(({ data: { data }, success }) => {
        if (success) {
          data.records.forEach(item => {
            this.tableData.forEach((its, idx) => {
              if (item.id === its.id) {
                this.tableData[idx].taskStatus = item.taskStatus
              }
            })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.pagination.handleSizeChange = (e) => {
      this.pagination.pageSize = e
      this.getRosterList()
    }
    this.pagination.handleCurrentChange = (e) => {
      this.pagination.currentPage = e
      this.getRosterList()
    }
    this.getRosterList()
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(updateTimer)
    next()
  },
  computed: {
    ...mapState(['userInfo'])
  },
}
</script>

<style lang="scss" scoped>
.my_list_page {
  .content {
    padding: 0px Translate(20px);
    border-radius: 5px;

    .page_title {
      height: Translate(76px);
      line-height: Translate(76px);
      font-size: Translate(18px);
      color: #333333;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    .my_table {
      .my_pagination {
        display: flex;
        justify-content: flex-end;
        margin: Translate(20px) 0 Translate(40px) 0;
      }

      .dots-icon {
        position: relative;
        top: Translate(-2px);
        margin-right: Translate(2px);
        background: #52c41b;
        width: Translate(6px);
        height: Translate(6px);
        border-radius: 50%;
        display: inline-block;
      }

      .error-dots {
        background: #ff8321;
      }

      .operation {
        color: #4088eb;
        display: flex;
        // justify-content: center;

        span {
          cursor: pointer;
        }

        .mr-10 {
          margin-right: Translate(10px);
        }
      }
    }
  }


  .search-main {
    padding: 0px Translate(20px);
    height: Translate(80px);
    border-bottom: Translate(16px) solid #f5f6f9;
    display: flex;
    align-items: center;

    .search-item {
      display: flex;
      align-items: center;

      .title {
        font-size: Translate(14px);
        color: #999999;
        margin-right: Translate(8px);
      }
    }

    .select-time {
      margin-left: Translate(30px);
    }
  }
}
</style>
import { render, staticRenderFns } from "./editPasswordDialog.vue?vue&type=template&id=fb6b064a&scoped=true&"
import script from "./editPasswordDialog.vue?vue&type=script&lang=js&"
export * from "./editPasswordDialog.vue?vue&type=script&lang=js&"
import style0 from "./editPasswordDialog.vue?vue&type=style&index=0&id=fb6b064a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6b064a",
  null
  
)

export default component.exports
<template>
    <div class="account_management_page">
        <div class="page_title">
            <span>
                下载中心
                <span style="color: #999;">({{ pagination.total || 0 }})</span>
            </span>
        </div>
        <div class="my_table">
            <el-table :data="tableData" style="width: 100%" border class="custom_my_table" max-height="550"
                v-loading="loading">
                <el-table-column align="center" type="index" label="序号" width="110" />
                <el-table-column align="center" v-for="(item, index) in tableColumn" :key="`column${index}`"
                    :prop="item.prop" :label="item.label" :minWidth="item.width">
                </el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="214">
                    <template slot-scope="scope">
                        <div class="operation">
                            <span @click="toDownload(scope.row)">下载</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="my_pagination">
                <el-pagination background @size-change="pagination.handleSizeChange"
                    @current-change="pagination.handleCurrentChange" :current-page="pagination.currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pagination.pageSize"
                    layout="total, sizes, jumper, prev, pager, next" :total="pagination.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api'
import { debounce } from 'lodash'
const tableColumn = [
    {
        prop: 'username',
        label: '名称',
        width: '184'
    }, {
        prop: 'username',
        label: '类型',
        width: '214'
    }, {
        prop: 'username',
        label: '创建时间',
        width: '216'
    }, {
        prop: 'username',
        label: '状态',
        width: '216'
    }
]
export default {
    data() {
        return {
            loading: false,
            tableData: [],
            tableColumn,
            pagination: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
                handleSizeChange: '',
                handleCurrentChange: '',
            },
            searchInfo: "",
            temporaryRow: {},
        }
    },
    methods: {
        toDownload() {

        },
        getChildrenList() {
            this.loading = true
            let params = {
                pageNo: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                searchInfo: this.searchInfo,
            }
            api.getChildrenList(params).then(({ data: { data } }) => {
                this.loading = false
                this.pagination.total = parseInt(data.total || 0)
                this.tableData = data.records
            }).catch(err => {
                console.log(err)
            })
        },
    },
    mounted() {
        this.pagination.handleSizeChange = (e) => {
            this.pagination.pageSize = e
            this.getChildrenList()
        }
        this.pagination.handleCurrentChange = (e) => {
            this.pagination.currentPage = e
            this.getChildrenList()
        }
        this.getChildrenList()
    },
}
</script>

<style lang="scss" scoped>
.account_management_page {
    padding: 0px Translate(20px);

    .page_title {
        height: Translate(76px);
        line-height: Translate(76px);
        font-size: Translate(18px);
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .my_table {
        .my_pagination {
            float: right;
            margin: Translate(20px) 0 Translate(40px) 0;
        }

        .operation {
            color: #4088eb;

            span {
                cursor: default;

                &:nth-child(2) {
                    margin-left: Translate(10px);
                }
            }
        }
    }
}
</style>
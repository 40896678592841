<template>
  <div class="code">
    <el-input :value="verificationCode" @input='onChange' placeholder="请输入验证码" size="small"></el-input>
    <el-button :disabled="isDisable" class="get-code" @click.prevent="getCode">
      {{sendBtnText}}
    </el-button>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  name: 'CodeInput',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
    },
    targetNum: {
      type: String,
    },
    type: {
      type: String,
      default: 'phone',//手机号、邮箱email
    }
  },
  data() {
    return {
      verificationCode: this.value,
      hasSend: false,
      countDown: 60,
    }
  },
  methods: {
    onChange(val) {
      this.$emit('change', val)
    },
    getCode() {
      this.hasSend = true
      const timer1 = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
        } else {
          this.hasSend = false
          this.countDown = 60
          clearInterval(timer1)
        }
      }, 1000)
      api.verificationCodeSend({
        mobile: this.targetNum,
        temId: 4
      }).then(({ code }) => {
        if (code === '0000') {
          message.success('验证码已发送')
        }
      })
    }
  },
  computed: {
    sendBtnText() {
      return this.hasSend ? `${this.countDown}s` : '获取验证码'
    },
    isDisable(){
      if(this.type == 'phone'){
        //手机
        return this.targetNum.length != 11 || this.hasSend
      }else{
        //邮箱 
        return !this.targetNum || this.hasSend
      }
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.verificationCode = newVal
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.code {
  position: relative;
  .captcha {
    width: Translate(80px);
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .error {
    position: absolute;
    color: #f56c6c;
    bottom: -35px;
    font-size: Translate(12px);
    line-height: Translate(20px) px;
    font-weight: 400;
  }
  .get-code {
    position: absolute;
    font-size: Translate(14px);
    color: #4088eb;
    font-weight: 400;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: #fff;
    padding: 0;
  }
}
</style>